import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  clickedInfoIcon = new Subject<any>();
  notifyInfoObservable$ = this.clickedInfoIcon.asObservable();

  clickedVidIcon = new Subject<any>();
  notifyVidObservable$ = this.clickedVidIcon.asObservable();

  constructor() { }

  public notifyModalofInfoClick(projectID){
    if (projectID != undefined){
      this.clickedInfoIcon.next(projectID);
    }
  }

  public notifyModalofVidClick(projectID, vidLink){
    if (vidLink || projectID){
      this.clickedVidIcon.next([projectID, vidLink]);
    }
  }

}
