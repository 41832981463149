<!-- Landing Section -->
<app-navbar></app-navbar>

<section class="landing-section">
<app-landinglogosvg></app-landinglogosvg>
<app-landingtextcontainer></app-landingtextcontainer>
</section>

<section id="about" class="w-screen p-10 lg:p-32">
  <div class="flex flex-row justify-center lg:justify-end items-center mt-10">
    <h3 class="font-normal text-xl mr-6"> ABOUT </h3>
    <img class="mr-3" width="100px" src="assets/svgs/divider.svg" onerror="this.onerror=null; this.src='assets/pngs/divider.png'">
  </div>
  <div class="flex flex-col justify-center items-center h-full mt-10">
    <app-aboutblobtransform></app-aboutblobtransform>
  </div>
</section>

<section id="works" class="px-10 md:px-20 w-screen lg:pt-0 min-h-screen" [ngClass]="isMobilePreview ? 'lg:px-32' : 'lg:pl-32 lg:pr-0'">
  <div class="flex flex-row justify-center items-center mt-10 mb-10" [ngClass]="isMobilePreview ? 'pl-0  lg:justify-end' : 'lg:justify-end pr-32'">
    <h3 class="font-normal text-xl mr-6"> WORKS </h3>
    <img class="mr-3" width="100px" src="assets/svgs/divider.svg" onerror="this.onerror=null; this.src='assets/pngs/divider.png'">
  </div>
  <app-projectsection (changedPreview)="changedPreview($event)"></app-projectsection>

</section>

<section id="contact" class="w-screen p-10 lg:p-32 h-screen">
  <app-contactsection></app-contactsection>
</section>


<!-- <button (click)="showDialog()">Open Dialog</button> -->
  <app-modal id="modal" class="hidden w-screen">
  </app-modal>

