import { ApplicationRef, Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

import { projects, Project } from 'src/app/projects';

import { ModalService } from 'src/app/services/modal.service';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {


  appElementRef: ElementRef;

  isVidClick: boolean = false;
  isInfoClick: boolean = false;
  videoLink: SafeResourceUrl;


  projects: Project[] = projects;

  activeProjectID: number;
  projectTags: string[];
  projectTitle: string;
  projectDuration: string;
  projectFunc: string;
  projectDate: string;

  constructor(private el: ElementRef,
    private modalService: ModalService,
    private domSanitizer: DomSanitizer,
    ) {
     }

  ngOnInit(): void {
    this.el.nativeElement.addEventListener('click', ()=> {
      this.close()
    });
    this.listenToVidClick();
    this.listenToInfoClick();
  }

  private close() {
    this.el.nativeElement.classList.remove('show')
    this.el.nativeElement.classList.add('hidden')
  }

    //Modal stuff
  showDialog(){
    let modal_t  = document.getElementById('modal')
    modal_t.classList.remove('hidden')
    modal_t.classList.add('show');
  }


  private listenToVidClick(){
    this.modalService.notifyVidObservable$.subscribe((previewData)=> {
      const vidLink = previewData[1];
      this.activeProjectID = previewData[0];
      this.isInfoClick = false;
      this.isVidClick = true;
      this.videoLink = this.domSanitizer.bypassSecurityTrustResourceUrl(vidLink);
      this.setContent();
      this.showDialog();
    });
  }

  private listenToInfoClick(){
    this.modalService.notifyInfoObservable$.subscribe((projectID)=> {
      this.activeProjectID = projectID;
      this.isVidClick = false;
      this.isInfoClick = true;
      this.setContent();
      this.showDialog();
    });
  }

  private setContent(){
    const activeProject = this.projects[this.activeProjectID];
    this.projectTags = activeProject.tags.split(",")
    this.projectTitle = activeProject.title;
    this.projectDate = activeProject.modalInfo.date;
    this.projectDuration = activeProject.modalInfo.duration;
    this.projectFunc = activeProject.modalInfo.functionalities;
  }

}
