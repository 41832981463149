<div class="flex flex-col justify-center items-center h-full">
  <h1 class="pb-10 text-center"> Let's work together </h1>
  <div class="flex flex-row items-center justify-center">
    <a href="mailto: hbcartojano@up.edu.ph">
      <div class="w-10 mr-3">
        <svg id="icon" xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 50 50">
          <path id="email" class="cls-1" d="M43.73,50H6.25a6,6,0,0,1-4.46-1.79A6,6,0,0,1,0,43.78V6.25A6,6,0,0,1,1.79,1.79,6,6,0,0,1,6.25,0h37.5a6,6,0,0,1,4.46,1.79A6,6,0,0,1,50,6.25v37.5A6.29,6.29,0,0,1,43.73,50ZM37.65,36.41a1.56,1.56,0,0,0,.91-.31,2,2,0,0,0,1.13-1.76V15.25a1.55,1.55,0,0,0-.23-.86,2.1,2.1,0,0,0-1.81-1.18H12a1.05,1.05,0,0,0-.48.16,2,2,0,0,0-1.38,1.83V34.34a1.63,1.63,0,0,0,.27.88,2.07,2.07,0,0,0,1.8,1.19Zm-2.19-4.28h-21v-12L25,28.55l10.46-8.37ZM25,23.09q-7-5.5-7.13-5.66h14.2Z"/>
        </svg>
      </div>
    </a>
    <a href="https://www.linkedin.com/in/hannah-cartojano-445092172/" target="_blank" rel="noopener noreferrer" >
      <div class="w-10">
        <svg id="icon" xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 50 50">
          <defs> <style> .cls-1 { fill-rule: evenodd;}</style> </defs>
          <path id="linkedin" class="cls-1" d="M43.89,0A6.12,6.12,0,0,1,50,6.11V43.89A6.12,6.12,0,0,1,43.89,50H6.11A6.12,6.12,0,0,1,0,43.89V6.11A6.12,6.12,0,0,1,6.11,0ZM15.67,41.34V19.29H8.34V41.34Zm26.42,0V28.7c0-6.78-3.62-9.93-8.44-9.93a7.29,7.29,0,0,0-6.6,3.64V19.29H19.72c.1,2.07,0,22.05,0,22.05h7.33V29a5.11,5.11,0,0,1,.24-1.79,4,4,0,0,1,3.76-2.68c2.65,0,3.71,2,3.71,5v11.8Zm-30-32.68a3.82,3.82,0,1,0-.1,7.62h0a3.82,3.82,0,1,0,.05-7.62Z"/>
        </svg>
      </div>
    </a>
  </div>
</div>
