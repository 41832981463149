import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-frontpage',
  templateUrl: './frontpage.component.html',
  styleUrls: ['./frontpage.component.scss'],
})
export class FrontpageComponent implements OnInit {

  isMobilePreview: boolean;

  constructor() { }

  ngOnInit(): void {
    this.isMobilePreview = true;
  }

  public changedPreview(isMobilePreview){
    this.isMobilePreview = isMobilePreview;
    // console.log(this.isMobilePreview);
  }


}
