import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Preview } from 'src/app/projects';

@Injectable({
  providedIn: 'root'
})


export class PreviewService {
  changedNode = new Subject<any>();

  projectPreview = {} as Preview;
  notifyObservable$ = this.changedNode.asObservable();

  constructor() {
   }

  public notifyPreviewComponent(previewData) {
    if (previewData) {
      this.changedNode.next(previewData);
    }
  }

}
