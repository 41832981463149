import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { gsap } from 'gsap/all';

@Component({
  selector: 'app-landingtextcontainer',
  template: `
  <div class="landing-text-container">
    <div class="flex flex-col lg:flex-row">
    <div class="relative overflow-hidden leading-snug">
      <h1 class="introduction noselect"> Hey, I'm </h1>
      <div #fadingEffect1 class="absolute inset-y-0 right-0"></div>
    </div>
    <div class="relative overflow-hidden leading-snug">
      <h1 class="introduction noselect lg:ml-5 highlight"> Hannah </h1>
      <div #fadingEffect2 class="absolute inset-y-0 right-0"></div>
    </div>
    </div>
    <div class="relative overflow-hidden leading-snug">
      <h2 class="font-extralight introduction2 noselect"> and I'm a developer</h2>
      <div #fadingEffect3 class="absolute inset-y-0 right-0"></div>
    </div>
  </div>
  `,
  styleUrls: ['./landingtextcontainer.component.scss']
})
export class LandingtextcontainerComponent implements AfterViewInit {

  @ViewChild('fadingEffect1') fadingEffect1: ElementRef;
  @ViewChild('fadingEffect2') fadingEffect2: ElementRef;
  @ViewChild('fadingEffect3') fadingEffect3: ElementRef;

  constructor() { }

  ngAfterViewInit(): void {
    this.animateText();

  }

  animateText(){
    const fadingEffect1 = this.fadingEffect1.nativeElement;
    const fadingEffect2 = this.fadingEffect2.nativeElement;
    const fadingEffect3 = this.fadingEffect3.nativeElement;
    const tl = gsap.timeline();
    tl.fromTo(fadingEffect1, {
      width: "100%",
      backgroundColor: "white"},{
      delay: 0.5,
      width: "0%",
      duration: 0.7,
      })
    .fromTo(fadingEffect2, {
      width: "100%",
      backgroundColor: "white"},{
      width: "0%",
      duration: 0.5,
    })
    .fromTo(fadingEffect3, {
      width: "100%",
      backgroundColor: "white"},{
      width: "0%",
      duration: 0.9,
    })


    tl.play();
  }

}
