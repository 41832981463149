<div class="flex flex-col-reverse lg:p-0 lg:flex-row project-section">
  <div #projectSection class="lg:w-1/2 lg:pr-10 flex flex-col">
    <p class="text-xl md:text-2xl lg:leading-10 text-justify order-3 lg:order-1 description"> {{projectDescription}}</p>
    <h1 class="text-3xl lg:text-4xl mt-10 order-1 lg:order-2 mb-2 title">{{projectTitle}}</h1>
    <div class="tags-container flex flex-row flex-wrap order-2 lg:order-3">
      <div class="tags mb-3 lg:mb-0" *ngFor="let tag of projectTags"> {{tag}}</div>
    </div>
    <app-projecttimeline class="order-4 overflow-y-hidden mt-5" (clicked)="clickedNodeSection($event)"></app-projecttimeline>
  </div>
  <div class="lg:w-1/2 preview" [ngClass]="isMobilePreview ? 'lg:pl-10 ':'lg-pl-0'" >
    <app-phonepreview *ngIf="isMobilePreview"></app-phonepreview>
    <app-desktoppreview *ngIf="!isMobilePreview"></app-desktoppreview>
  </div>

</div>
