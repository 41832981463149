import { Component, ElementRef, ViewChild, AfterViewInit, AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import throttle from 'lodash-es/throttle';

import { gsap } from 'gsap/all';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent implements AfterViewInit, AfterContentChecked {

  screenWidth: number;
  isMobile: boolean;
  isClicked: boolean;
  lastScroll: number;
  isScrolledUp: boolean;
  isScrolledDown: boolean;
  showMenu: boolean;
  @ViewChild('menu') menu: ElementRef;
  @ViewChild('triggerMenuWrapper') triggerMenuWrapper: ElementRef;

  constructor(private window: Window, private hostElem: ElementRef,
    private changeDedectionRef: ChangeDetectorRef) {
    this.screenWidth = this.window.screen.width;
    this.isMobile = false;
    this.isClicked = false;
    this.lastScroll = 0;
    this.isScrolledUp = false;
    this.isScrolledDown = true;
   }
  ngAfterContentChecked(): void {
    this.changeDedectionRef.detectChanges();
  }

  ngAfterViewInit(): void {
    this.checkforMobile();
    this.listenforResize();

    this.disappearMenu();
    this.listentoScroll();

  }

  // Listens for resize event and invokes checkforMobile
  private listenforResize(){
    this.window.addEventListener('resize', throttle(() => {
      this.screenWidth = this.window.screen.width;
      // console.log("Current Screen Width:" + this.screenWidth);
      this.checkforMobile();
    }, 500));
  }

  // Checks if screenWidth is below set threshold and toggles isMobile and animates out the menu
  private checkforMobile(){
    if (this.screenWidth > 768){
      this.isMobile = false;
    } else if (this.screenWidth){
      this.isMobile=true;
      this.animateMobileMenuOut();
    }
  }

  // Invoked when burger icon is cliked and calls respective animations, toggles isClicked
  public onBurgerClick(){
    console.log("this is clicked");
    if (this.isClicked) {
      this.animateMobileMenuOut();
    } else{
    this.isClicked = true;
    this.animateMobileMenuIn();
    }
  }

  // Animation for entrace of mobile menu
  private animateMobileMenuIn(){
    const menu = this.menu.nativeElement;
    const document = this.hostElem.nativeElement;
    const elements = document.querySelectorAll(".menu-font-mobile");
    gsap.fromTo(menu, {
      y:-800,
    }, {
      y: 0,
      ease: "back"
    })
    elements.forEach(()=> {
      gsap.fromTo(".menu-font-mobile",{
        y:-50,
        color: "transparent",
      } ,{
        y: 0,
        delay: 0.2,
        stagger:0.3,
        color: "#261a36",
        ease: "ease"
      })
    })

  }

  //Animation for exit of mobile menu
  private animateMobileMenuOut(){
    const menu = this.menu.nativeElement;
    gsap.to(menu, {
      y: -900,
      ease: "ease",
      onComplete: ()=> {this.isClicked = false}
    })
  }

  //Invoked when user clicks on mobile menu item
  public onMenuClick(item){
    this.animateMobileMenuOut();

  }


  private easeMenuIn(){
    const triggerMenuWrapper = this.triggerMenuWrapper.nativeElement;
    gsap.to(triggerMenuWrapper,{
      transform: "none",
      duration: 0.3,
      ease: "ease",
    });
  }

  private easeMenuOut(){
    const triggerMenuWrapper = this.triggerMenuWrapper.nativeElement;
    gsap.to(triggerMenuWrapper, {
      duration: 0.3,
      transform: "translate3d(0, -100%, 0)",
      ease: "ease",
    });
  }

  private disappearMenu(){
    const triggerMenuWrapper = this.triggerMenuWrapper.nativeElement;
    gsap.to(triggerMenuWrapper, {
      duration: 1,
      transform: "translate3d(0, -500%, 0)",
      ease: "ease",
    });
  }


  private listentoScroll(){
    // Throttling to listen every 300 ms only
    this.window.addEventListener('scroll', throttle(() => {
      const currentScroll = this.window.pageYOffset;
      // console.log("Current Scroll Y:" + currentScroll);

      if (currentScroll <= 50){
        console.log("rest position")
        this.easeMenuOut();
        this.lastScroll = currentScroll;
        return;
      }

      if (currentScroll > this.lastScroll){
        this.easeMenuIn()
        // console.log("Invoked scroll down");
        this.lastScroll = currentScroll;
        // console.log("Last Scroll Y:" + this.lastScroll);
      } else if (currentScroll < this.lastScroll){
        this.easeMenuIn();
        // console.log("Invoked scroll up");
        this.lastScroll = currentScroll;
        // console.log("Last Scroll Y:" + this.lastScroll);
      }
    }, 1));
  }



}
