
import { AfterContentChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';

import {gsap, ScrollTrigger} from 'gsap/all';
gsap.registerPlugin(ScrollTrigger);

import {Project, projects} from 'src/app/projects';

import { PreviewService } from 'src/app/services/preview.service';


@Component({
  selector: 'app-projectsection',
  templateUrl: './projectsection.component.html',
  styleUrls: ['./projectsection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectsectionComponent implements OnInit, AfterViewInit, AfterContentChecked {

  projects: Project[];
  isMobilePreview: boolean;
  activeNode: number = 0;
  activeProject: Project;
  // Data to show on template
  projectTitle: string;
  projectDescription: string;
  projectTags: string [];
  @Output() changedPreview: EventEmitter<any> = new EventEmitter();
  @ViewChild('projectSection') projectSection: ElementRef;


  //Interface for variables depening on screensize

  constructor(private previewService: PreviewService,
    private changeDedectionRef: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.projects = projects;
    this.activeProject = projects[0]
    this.setContent();
    this.listenToScroll();
    this.changeDedectionRef.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.changeDedectionRef.detectChanges();
  }

  private listenToScroll(){
    const projectSection = this.projectSection.nativeElement;
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: projectSection,
        // markers: true,
        start: "top center",
        onEnter: ({isActive}) => console.log(`Triggered content animation: ${isActive}`)
      }});

      this.triggerEntranceAnimations(tl);

  }

  private triggerEntranceAnimations(tl){
    tl.addLabel("entranceAnimations");
    tl.fromTo(".description",{opacity: "0%"},{opacity: "100%", delay: 0.2}, "entranceAnimations");
    tl.fromTo(".title",{opacity: "0%"},{opacity: "100%", delay: 0.3}, "entranceAnimations");
    tl.fromTo(".tags-container",{opacity: "0%"},{opacity: "100%", delay: 0.5}, "entranceAnimations");
    tl.fromTo(".preview",{opacity: "0%"},{opacity: "100%", delay: 0.3}, "entranceAnimations");
    // document.querySelectorAll(".tags").forEach( tag => {
    //   tl.fromTo(tag, {opacity: "0%"},{opacity: "100%", delay: 0.3}, "entranceAnimations")
    // })
  }

  public clickedNodeSection(clickedNodeId){
    const tl = gsap.timeline();
    this.activeNode = clickedNodeId.split("-")[1]; //Returns the index of the node
    //Fetches data, sets the properties and triggers animation
    this.activeProject = this.projects[this.activeNode];
    this.triggerEntranceAnimations(tl);
    tl.play();
    this.setContent();
  }

  private setContent(){

    // Text Section
    this.projectTitle = this.activeProject.title;
    this.projectTags = this.activeProject.tags.split(",")
    // this.projectDescription = "Lorem ipsum dolor sit amet, porttitor leo non, vulputate elit. Nam nec nisi libero. Cras pharetra enim urna, et rutrum ligula consequat ac. Morbi vitae libero commodo, iaculis lorem euismod, fringilla metus. Donec ipsum velit, sodales nec erat nec, sagittis tristique sapien.  Cras pharetra enim urna, et rutrum ligula consequat ac. Morbi vitae libero commodo.";
     this.projectDescription = this.activeProject.description;
    this.isMobilePreview = this.activeProject.isMobilePreview;

    if (this.isMobilePreview){
      this.changedPreview.emit(true)
    } else{
      this.changedPreview.emit(false)
    }

    //Preview Section
    const projectPreview = this.previewService.projectPreview;
    projectPreview.projectID = this.activeNode;
    projectPreview.projectImage = this.activeProject.image;
    projectPreview.projectInfoLink = this.activeProject.infoLink;
    projectPreview.projectVidLink = this.activeProject.videoLink;
    projectPreview.projectLiveLink = this.activeProject.liveLink;

    this.previewService.notifyPreviewComponent(projectPreview);

  }


}
