import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FrontpageComponent } from './pages/frontpage/frontpage.component';
import { LandinglogosvgComponent } from './components/landinglogosvg/landinglogosvg.component';
import { LandingtextcontainerComponent } from './components/landingtextcontainer/landingtextcontainer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { AboutblobtransformComponent } from './components/aboutblobtransform/aboutblobtransform.component';
import { ProjectsectionComponent } from './components/projectsection/projectsection.component';
import { ProjecttimelineComponent } from './components/projecttimeline/projecttimeline.component';
import { PhonepreviewComponent } from './components/phonepreview/phonepreview.component';
import { DesktoppreviewComponent } from './components/desktoppreview/desktoppreview.component';
import { ExploretabComponent } from './components/exploretab/exploretab.component';

import { TippyModule, popperVariation, } from '@ngneat/helipopper';
import { tooltipVariation } from './tooltip.js';
import { ContactsectionComponent } from './components/contactsection/contactsection.component';
import { ModalComponent } from './components/modal/modal.component';

import {SlideshowModule} from 'ng-simple-slideshow';

@NgModule({
  declarations: [
    AppComponent,
    FrontpageComponent,
    LandinglogosvgComponent,
    LandingtextcontainerComponent,
    NavbarComponent,
    AboutblobtransformComponent,
    ProjectsectionComponent,
    ProjecttimelineComponent,
    PhonepreviewComponent,
    DesktoppreviewComponent,
    ExploretabComponent,
    ContactsectionComponent,
    ModalComponent
  ],
  imports: [
    TippyModule.forRoot({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
      }
    }),
    BrowserModule,
    AppRoutingModule,
    SlideshowModule
  ],
  providers: [{ provide: Window, useValue: window }],
  bootstrap: [AppComponent]
})
export class AppModule { }
