import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';

import { PreviewService } from 'src/app/services/preview.service';

import { ModalService } from 'src/app/services/modal.service';

import { Preview } from 'src/app/projects';

import { TimelineMax } from 'gsap/all';


@Component({
  selector: 'app-exploretab',
  templateUrl: './exploretab.component.html',
  styleUrls: ['./exploretab.component.scss']
})
export class ExploretabComponent implements OnInit, AfterViewInit {

  projectPreview = {} as Preview;
  i: number;

  constructor(public previewService: PreviewService, public modalService: ModalService) { }

  ngOnInit(): void {
    this.i = 0;
    this.setContent();
    this.updateContent();
  }

  ngAfterViewInit(): void {
    const tl = new TimelineMax();
    document.querySelectorAll(".icon-box").forEach( elem => {
      tl.fromTo(elem,{x:-50, opacity: 0 }, {x: 0, opacity:1, stagger: 0.3})
    })

  }


  public hoveredExplore(){

  }

  private updateContent(){
    this.previewService.notifyObservable$.subscribe((previewData: Preview)=> {
      this.projectPreview.projectID = previewData.projectID;
      this.projectPreview.projectInfoLink = previewData.projectInfoLink;
      this.projectPreview.projectLiveLink = previewData.projectLiveLink;
      this.projectPreview.projectVidLink = previewData.projectVidLink;
      console.log(this.projectPreview);
    });

  }

  private setContent(){
    if (this.i > 1){return}
    const projectPreview = this.previewService.projectPreview;
    this.projectPreview.projectID = projectPreview.projectID;
    this.projectPreview.projectImage = projectPreview.projectImage;
    this.projectPreview.projectInfoLink = projectPreview.projectInfoLink;
    this.projectPreview.projectLiveLink = projectPreview.projectLiveLink;
    this.projectPreview.projectVidLink = projectPreview.projectVidLink;
    console.log(this.projectPreview);
    this.i++;
  }

  public openLiveLink(){
    window.open(this.projectPreview.projectLiveLink, "_blank");
  }

  public openInfoLink(){
    this.modalService.notifyModalofInfoClick(this.projectPreview.projectID);
  }

  public openVidLink(){
    this.modalService.notifyModalofVidClick(this.projectPreview.projectID, this.projectPreview.projectVidLink );
  }


}
