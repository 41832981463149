import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

import { gsap } from 'gsap/all';

import KUTE from 'kute.js'

@Component({
  selector: 'app-aboutblobtransform',
  templateUrl: './aboutblobtransform.component.html',
  styleUrls: ['./aboutblobtransform.component.scss']
})
export class AboutblobtransformComponent implements AfterViewInit {

  isActive: boolean;
  tl: any;
  count: number = 0;
  tween: any;
  arrayofTweens: any [];
  label: string = "some Web and App Development";
  @ViewChild('fadingEffect') fadingEffect: ElementRef;

  constructor() { }

  ngAfterViewInit(): void {
    this.testAnimation();
    this.changeLabel("some Web and App Development");
    // this.startAnimation();
  }


  async testAnimation(){
    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
    const progToData: any [] = [KUTE.to('#prog-c', { path: '#data-c' }, {duration: 1000}),
    KUTE.to('#prog-b', { path: '#data-b' }, {duration: 1000}),
    KUTE.to('#prog-a', { path: '#data-a' }, {duration: 1000, onStart: () => this.changeLabel( "some Data Analysis"), onComplete: () => this.pauseSingleAnimation(0)})];
    const datatoMl: any [] = [KUTE.to('#prog-c', { path: '#ml-c' }, {duration: 1000}),
    KUTE.to('#prog-b', { path: '#ml-b' }, {duration: 1000}),
    KUTE.to('#prog-a', { path: '#ml-a' }, {duration: 1000, onStart: () => this.changeLabel("some Machine Learning"), onComplete: () => this.pauseSingleAnimation(1)})];
    const mltoChem: any [] = [KUTE.to('#prog-c', { path: '#chem-c' }, {duration: 1000}),
    KUTE.to('#prog-b', { path: '#chem-b' }, {duration: 1000}),
    KUTE.to('#prog-a', { path: '#chem-a' }, {duration: 1000, onStart: () => this.changeLabel("some Materials Engineering"), onComplete: () => this.pauseSingleAnimation(2)})];
    const chemtoProg: any [] = [KUTE.to('#prog-c', { path: '#prog-c' }, {duration: 1000}),
    KUTE.to('#prog-b', { path: '#prog-b' }, {duration: 1000}),
    KUTE.to('#prog-a', { path: '#prog-a' }, {duration: 1000, onStart: () => this.changeLabel(  "some Web and App Development"), onComplete: () => this.pauseSingleAnimation(4)})];

    this.arrayofTweens = [];

    this.arrayofTweens.push(progToData, datatoMl, mltoChem, chemtoProg );

    await delay(1600);

    this.startSequence(progToData);


  }

  private startSequence(array: any []){
    array.forEach((elem: any) => {
      elem.start();
    })
  }


  async pauseSingleAnimation(i){
    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
    // Pause time per icon
    await delay(2000);

     if ( i < 4) {
      const y = i + 1;
      this.startSequence(this.arrayofTweens[y]);
    } else {
      this.startSequence(this.arrayofTweens[0]);
    }

  }

  //Function called every completion of sequence for one icon
  private changeLabel(label){
    this.label = label;
    const fadingEffect = this.fadingEffect.nativeElement;
    const tl = gsap.timeline();
    tl.fromTo(fadingEffect, {
      width: "100%",
      backgroundColor: "white"},{
      width: "0%",
      duration: 0.8,
      })

  }

  private animateDots(){
    const tl = gsap.timeline();
  }



}
