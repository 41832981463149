import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';

import {gsap, ScrollTrigger} from 'gsap/all';
gsap.registerPlugin(ScrollTrigger);


@Component({
  selector: 'app-projecttimeline',
  template: `
  <svg id="timeline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 50">
  <path id="line" class="cls-1" d="M616.78,32h-600V20.72h600Z"/>
  <path id="circle-0" class="cls-1 circle" (click)="clickedNode($event)" (mouseenter)="hoveredNode($event)" d="M30.28,26.37a13.5,13.5,0,1,1-13.5-13.5A13.5,13.5,0,0,1,30.28,26.37Z" />
  </svg>
  `,
  styleUrls: ['./projecttimeline.component.scss'],

})
export class ProjecttimelineComponent implements AfterViewInit {

  @Output() clicked: EventEmitter<any> = new EventEmitter();
  prevActiveNodeId: string;
  activeNodeId: string;

  constructor() { }

  ngAfterViewInit(): void {
    this.listentoScroll()
    gsap.set("path#circle-0", {scale: 1.3});
    this.activeNodeId = "circle-0";
  }

  private listentoScroll(){
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: "#timeline",
        // markers: true,
        start: "bottom bottom",
        onEnter: ({isActive}) => console.log(`Triggered timeline animation: ${isActive}`)
      }});

    this.setTimeline(tl);
  }

  private setTimeline(tl){
    let offsetX = 0;
    this.addPoints(4)
    tl.addLabel("setTimeline")
    tl.fromTo("path#line", {scaleX: 0.1} ,{duration: 1.2, ease: "ease", scaleX: 1}, "setTimeline");
    document.querySelectorAll("path.circle").forEach( elem => {
      tl.to(elem, {duration: 1.5, x: offsetX, stagger: 0.3}, "setTimeline")
      offsetX += 101;
    })
  }

  private addPoints(n){
    const svg = document.getElementById("timeline");
    const nodeBasis = document.getElementById("circle-0");
    for (let i =0; i <=n; i++){
      const newNode = nodeBasis.cloneNode() as HTMLElement;
      newNode.setAttribute("id", `circle-${i+1}`);
      newNode.style.fill = "#959595";
      newNode.onclick = ($event) => {this.clickedNode($event)};
      newNode.onmouseenter = ($event) => {this.hoveredNode($event)};
      svg.appendChild(newNode);
    }
  }

  public clickedNode(event){
    const clickedNodeId = event.target.id;

    if (clickedNodeId === this.activeNodeId){
      return;
    }
    //Emits to parent component
    this.clicked.emit(clickedNodeId);
    //Makes node active
    this.makeNodeActive(clickedNodeId);
  }

  public async hoveredNode(event){
    const node = event.target;
    if (node.id === this.activeNodeId){
      return;
    }
    const tl = gsap.timeline({repeat: 1,
      yoyo: true});
    tl.fromTo(node, {scale: 1, fill: "#959595"}, {scale: 1.3, fill: "#261a36", ease: "power1", duration: 0.3});
    // console.log("Hovered Node ", node.id)
  }

  private makeIntoSelector(id){
    const selector = `path#${id}`;
    return selector;
  }

  private makeNodeActive(clickedNodeId: string){
     //Store prevActiveNodeId
     this.prevActiveNodeId = this.activeNodeId;
    //  console.log("prevActiveNode "+ this.prevActiveNodeId);
     //Changes activeNodeId within this component and does necessary transformations
     this.activeNodeId = clickedNodeId;
    //  console.log("activeNodeId "+ this.activeNodeId);

     if (this.prevActiveNodeId != this.activeNodeId){
      const clickedNodeSelector = this.makeIntoSelector(clickedNodeId);
      const prevNodeSelector = this.makeIntoSelector(this.prevActiveNodeId);
      const tl = gsap.timeline();
      tl.addLabel("makeActive")
      .to(clickedNodeSelector, {scale: 1.3, fill: "#261a36", ease: "power1", onComplete: () => {console.log("made active "+ clickedNodeSelector)}}, "makeActive")
      .to(prevNodeSelector, {scale: 1, fill: "#959595", ease: "power1", onComplete: () => {console.log("made inactive "+ prevNodeSelector)}}, "makeActive");
    } else{
      return;
    }
  }

  //Find some way to disable hovering when element is being selected
  // Bring back the transformY
}
