import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contactsection',
  templateUrl: './contactsection.component.html',
  styleUrls: ['./contactsection.component.scss']
})
export class ContactsectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
