<div class="flex flex-row justify-center items-center relative w-full min-h-full overflow-y-visible" >
  <div class="w-full lg:w-5/6 inline-block relative">
    <img class="w-full" *ngIf="projectPreview.projectImage" [src]="projectPreview.projectImage">
    <app-exploretab class="absolute bottom-16 sm:bottom-32 right-9 z-50"></app-exploretab>
  </div>
</div>



<!-- <div class="flex flex-row justify-center items-center relative w-full min-h-full overflow-y-visible" [ngClass]="isLargeScreen ? '':''" >
  <div class="w-full absolute top-10 md:top-16 left-0">
    <img *ngIf="projectPreview.projectImage" [src]="projectPreview.projectImage" class="block w-11/12  lg:w-9/12 mx-auto my-auto">
  </div>
  <div #svg class="w-full lg:w-5/6 inline-block relative">
     <svg class="" id="monitor" height="100%" width="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 488.09">
      <path id="monitor" data-name="monitor" class="cls-1" d="M21.78,1.21c-8.18,2.58-17,10.93-20,19.11L0,25.05.17,213.31.43,401.58,3.36,407a33.78,33.78,0,0,0,15.41,14.64l4.9,2.32,91.94.26,91.85.17v30.82l-30.73.34c-29.79.35-31,.43-35.81,2.33-10.16,4-19.46,15.66-20.92,26.17l-.52,4H296c143.59,0,176.56-.17,176.56-1.12,0-2.75-2.84-11.45-4.82-14.89-2.84-4.73-9.47-10.76-14.64-13.26-6.28-2.92-11.88-3.44-37.1-3.44h-23.5v-31h90.65c82.12,0,91.07-.17,94.94-1.46a34.25,34.25,0,0,0,19.37-17.05l2.07-4.3.26-188.27L600,25.05l-1.89-5c-2.59-7-11.19-15.58-18.17-18.17L575,0,300.17.09C76.61.09,24.71.34,21.78,1.21ZM575.9,204v171.3H24.1V32.71H575.9Z"/>
    </svg>
  </div>
</div> -->
