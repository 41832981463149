import { AfterContentChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import throttle from 'lodash-es/throttle';

import { PreviewService } from 'src/app/services/preview.service';

import { Preview } from 'src/app/projects';


@Component({
  selector: 'app-desktoppreview',
  templateUrl: './desktoppreview.component.html',
  styleUrls: ['./desktoppreview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesktoppreviewComponent implements AfterViewInit, AfterContentChecked {
  i: number;
  projectPreview = {} as Preview;
  containerHeight: number;
  screenWidth: number;
  isLargeScreen: boolean;
  @ViewChild('svg') svg:ElementRef;

  constructor(private previewService: PreviewService,
    private changeDedectionRef: ChangeDetectorRef,
    private window: Window) {
      this.screenWidth = this.window.screen.width;
      if (this.screenWidth > 1024){
        this.isLargeScreen = true;
      }
     }

  ngAfterViewInit(): void {
    this.i = 0;
    this.setContent();
    this.updateContent();
    // this.listenforResize();
  }

  ngAfterContentChecked(): void {
    this.changeDedectionRef.detectChanges();
  }


  private setContent(){
    if (this.i > 1){return}
    const projectPreview = this.previewService.projectPreview;
    this.projectPreview.projectID = projectPreview.projectID;
    this.projectPreview.projectImage = projectPreview.projectImage;
    this.projectPreview.projectInfoLink = projectPreview.projectInfoLink;
    this.projectPreview.projectLiveLink = projectPreview.projectLiveLink;
    this.projectPreview.projectVidLink = projectPreview.projectVidLink;
    this.i++;
  }

  private updateContent(){
    this.previewService.notifyObservable$.subscribe((previewData: Preview)=> {
      this.projectPreview.projectID = previewData.projectID;
      this.projectPreview.projectImage = previewData.projectImage;
      this.projectPreview.projectInfoLink = previewData.projectInfoLink;
      this.projectPreview.projectLiveLink = previewData.projectLiveLink;
      this.projectPreview.projectVidLink = previewData.projectVidLink;
    });

  }

  private listenforResize(){
    this.window.addEventListener('resize', throttle(() => {
      this.screenWidth = this.window.screen.width;

      if (this.screenWidth > 1024){
        this.isLargeScreen = true;
        return;
      }
      // console.log("Current Screen Width:" + this.screenWidth);
      this.adjustDimensions();
    }, 500));
  }

  private adjustDimensions(){
    const width = this.svg.nativeElement.offsetWidth;
    const height = this.svg.nativeElement.offsetHeight;

    this.containerHeight = height;
    console.log(this.containerHeight);
    //This should give me height and wdith of

    console.log(width, height);
  }

}
