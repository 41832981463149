<div class="flex flex-row justify-center items-center relative">
  <div class="relative inline-block w-5/6 md:w-1/2">
    <img *ngIf="projectPreview.projectImage" [src]="projectPreview.projectImage" class="block w-5/6 mx-auto">
    <app-exploretab class="absolute bottom-0 right-5 md:right-0 z-50 md:pr-8 pb-8 overflow-hidden"></app-exploretab>
    <svg class="absolute top-0 left-0" id="phone" xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" viewBox="0 0 221.01 410">
      <path  id="phone" data-name="phone" class="cls-1" d="M221,15.63A15.7,15.7,0,0,0,205.29,0H15.7A15.73,15.73,0,0,0,0,15.63H0V394.17H0c0,.16,0,.33,0,.5C0,403.13,7.12,410,15.9,410l.38,0v0H205.51A15.43,15.43,0,0,0,221,394.67c0-.17,0-.34,0-.5h0V15.71h0v-.08ZM207.91,32v359.2H13.08V21.88H207.91Z"/>
    </svg>
  </div>

</div>
<!-- d of with button -->
<!-- M207.91,347.83H13.08V32H207.91ZM95.34,379.9a15.58,15.58,0,1,1,15.57,15.73A15.65,15.65,0,0,1,95.34,379.9M221,394.17h0V15.71h0v-.08h0A15.7,15.7,0,0,0,205.29,0H15.7A15.73,15.73,0,0,0,0,15.63H0V394.17H0c0,.16,0,.33,0,.5C0,403.13,7.12,410,15.9,410l.38,0v0H205.51A15.43,15.43,0,0,0,221,394.67c0-.17,0-.34,0-.5 -->
