<nav class="page-header">
    <div #triggerMenuWrapper class="trigger-menu-wrapper flex flex-row justify-between border-b border-gray-300 w-full bg-white">
      <div class="py-4 w-1/4 ml-4 lg:ml-24">
        <img  width="75px" src="assets/svgs/logo.svg" onerror="this.onerror=null; this.src='assets/pngs/logo.png'">
      </div>
      <div *ngIf="!isMobile" class="menu flex flex-row w-2/4 justify-evenly items-center justify-self-end">
        <a href="#about" class="menu-font"> ABOUT </a>
        <a href="#works" class="menu-font"> WORKS </a>
        <a href="#contact" class="menu-font"> CONTACT </a>
      </div>
      <button *ngIf="isMobile" class="px-10" (click)="onBurgerClick()">
        <img  width="30px" src="assets/svgs/menu.svg" onerror="this.onerror=null; this.src='assets/pngs/menu.png'">
      </button>
    </div>
    <div #menu class="w-full h-full flex flex-col justify-center items-center"  [ngClass]="isClicked ? 'clicked' : 'hide'">
      <a href="#about" class="menu-font menu-font-mobile" (click)='onMenuClick("about")'> ABOUT </a>
      <a href="#works" class="menu-font menu-font-mobile" (click)='onMenuClick("works")'> WORKS </a>
      <a href="#contact" class="menu-font menu-font-mobile" (click)='onMenuClick("blog")'> CONTACT </a>
    </div>
  </nav>
