export interface Project{
  projectID: number;
  title: string;
  description: string;
  tags: string;
  image: string;
  infoLink: string;
  videoLink: string;
  liveLink: string;
  isMobilePreview: boolean;
  modalInfo: ModalInfo;
}

export interface Preview{
  projectID: number;
  projectImage: string;
  projectInfoLink: string;
  projectVidLink: string;
  projectLiveLink: string;
}

export interface ModalInfo{
  duration: string;
  date: string;
  functionalities: string;
}

export const projects: Project[] = [
  {
    projectID: 0,
    title: "Alexandria: A Document Sharing Mobile App",
    description: "Alexandria is a CRUD demonstration project aiming to offer the core functionalities of resource sharing platforms such as Scribd. Signed-in users can upload, modify and delete their documents which are shared in a community pool. These documents can then be downloaded. Web technologies were used and native APIs were accessed through Ionic.",
    tags: "Angular,Ionic,Firebase,Nodejs",
    image: "assets/images/phone-alexandria.png",
    infoLink: "",
    videoLink: "https://www.youtube.com/embed/9QyYp88NSAU",
    liveLink: "",
    isMobilePreview: true,
    modalInfo: {
      duration: "1-2 weeks",
      date: "March 2021",
      functionalities: "Create Document Entries, Modify Document Entries, Delete Document Entries, Real-time Feed Update, User Sign-up, and Login.",
    }
  },
  {
    projectID: 1,
    title: "PARCS Directory Website",
    description: "The PARCS Directory website features a rich user and content management system built with Laravel. Visitors of the website can make use of the live search functionality to look for members. Signed-in members and admins are given varying levels of content creation and moderation permissions such the creation of posts and the editing of directory details.",
    tags: "Laravel,Livewire,MySQL,Apache,Debian",
    image: "assets/images/monitor-parcs.png",
    infoLink: "",
    videoLink: "https://www.youtube.com/embed/SSmrD7PARUk",
    liveLink: "https://parcs.org.ph/",
    isMobilePreview: false,
    modalInfo: {
      duration: "several months",
      date: "January 2021",
      functionalities: "Content Management System, User Profile Management, User Management System, Live Search with Filters, Admin Panel, VPS hosted, and Email Server.",
    }
  },
  {
    projectID: 2,
    title: "Sentiment Analysis with Filipino Text",
    description: "Through Google Cloud APIs, text in Filipino or Taglish (a mixture of it with English) is given a sentiment score. The value indicates overall how positive or negative the inputted text is. A mini-research was also conducted where the accuracy of this tool was assessed against a human surveyor.",
    tags: "Angular,Firebase,Nodejs,Google Cloud APIs",
    image: "assets/images/phone-sentiment1.png",
    infoLink: "",
    videoLink: "https://www.youtube.com/embed/nmmWkKeOtmM",
    liveLink: "",
    isMobilePreview: true,
    modalInfo: {
      duration: "few days",
      date: "April 2021",
      functionalities: "Natural Language Processing, Language Translation, and Real-Time Updating. ",
    }
  },
  {
    projectID: 3,
    title: "Cutting Edge Skills Guild Website",
    description: "The Cutting Edge Skills Guild Website is built from a completely custom Wordpress theme with its own contact form plugin. The website, designed and actualized from the ground-up, features numerous animations built with SVGs and CSS. A custom blog section is also provided.",
    tags: "WP Custom Theme,Adobe AE,Adobe Illustrator,Lottie",
    image: "assets/images/monitor-CESGI.png",
    infoLink: "",
    videoLink: "https://www.youtube.com/embed/Th0wUwjwMqo",
    liveLink: "https://cutting-edge-skills.com/",
    isMobilePreview: false,
    modalInfo: {
      duration: "months",
      date: "December 2020",
      functionalities: "Customized Blog Section, SVG and CSS animations, and contact form plug-in.",
    }
  },
  {
    projectID: 4,
    title: "Kotlin Image Labelling App with Custom Model",
    description: "In this mobile app built natively, a model was re-trained with Keras by redefining its output nodes. It is then loaded on-demand so that installation bundle size is minimized. It also allows for easy swapping of models as they are cloud-hosted.",
    tags: "Kotlin,Android Studio,Keras,Python,GCP",
    image: "assets/images/phone-kotlin3.JPG",
    infoLink: "",
    videoLink: "https://www.youtube.com/embed/3gb8Q5Yd95M",
    liveLink: "",
    isMobilePreview: true,
    modalInfo: {
      duration: "few days",
      date: "April 2021",
      functionalities: "Custom Machine Learning Model, Cloud-hosted Model, and Native Development.",
    }
  },
  {
    projectID: 5,
    title: "Doyenne Consultants Website",
    description: "Doyenne Consultants Website is a quick project built on top a modified Wordpress theme. Its page elements are Elementor-friendly and configurable",
    tags: "WPCustom Theme, Elementor",
    image: "assets/images/monitor-doyenne.png",
    infoLink: "",
    videoLink: "https://www.youtube.com/embed/01jNhdX8S98",
    liveLink: "https://doyenneconsultants.com/",
    isMobilePreview: false,
    modalInfo: {
      duration: "months",
      date: "Jan 2021",
      functionalities: "Elementor-friendly, and Modified Wordpress Theme.",
    }
  },
]
