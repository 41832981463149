<div class="modal lg:overflow-y-hidden">
  <div class="modal-body mx-10 lg:mx-32 bg-white">
      <div *ngIf="isVidClick" class="w-full flex flex-col lg:flex-row justify-between">
        <iframe class="video-player lg:flex-none"
        [src]="videoLink">
        </iframe>
        <div class="flex flex-col items-start lg:flex-grow lg:ml-10">
          <h1 class="text-3xl lg:text-2xl mt-10 lg:mt-0"> {{projectTitle}}</h1>
          <div class="tags-container flex flex-row flex-wrap mb-5">
            <div class="tags mb-2" *ngFor="let tag of projectTags"> {{tag}}</div>
          </div>
          <p class="description"> This project was started on {{projectDate}} and was completed in {{projectDuration}} time. Listed non-exhaustively are its functionalities and/or features: {{projectFunc}}
        </div>
      </div>
      <div *ngIf="isInfoClick" class="mx-10 lg:mx-32 bg-white">
        <!-- <slideshow *ngIf="imageUrls.length"
        [imageUrls]="imageUrls"
        [minHeight]="'400px'"
        [showArrows]="false"
        ></slideshow> -->
      </div>
  </div>
</div>
<div #modalBackground class="modal-background"></div>
