import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import {gsap} from 'gsap/all';

@Component({
  selector: 'app-landinglogosvg',
  template: `
    <div #landingLogoContainer class="landing-logo-container">
    <svg id="logo"  width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 545.62 483.01" preserveAspectRatio="xMaxYMin meet"><defs><style>.cls-1{fill:#261a36;}</style></defs><path class="cls-1" d="M322.17,153.75V325.5H290.92V252.25H205.17V325.5H173.92V153.75h31.25v70h85.75v-70Z" transform="translate(-25.51 -58.99)"/><path class="cls-1" d="M278.77,362.09c0-48.25,41.25-88.75,90.25-88.75a90.29,90.29,0,0,1,64.75,27.75l-22.25,20.75c-10.5-12.25-26-20-42.5-20-31.75,0-58.5,27.5-58.5,60.25,0,33,26.75,60.5,58.5,60.5,16.75,0,32-7.75,42.75-20l22,20.5a89.53,89.53,0,0,1-64.75,28C320,451.09,278.77,410.34,278.77,362.09Z" transform="translate(-25.51 -58.99)"/><path class="cls-1" d="M546.89,275.52,444.29,97.8A25,25,0,1,0,400,75.23H198.41a25,25,0,1,0-42.68,24.7L55.79,273a25,25,0,1,0-5.28,49.43,24.56,24.56,0,0,0,4-.33L157,499.63a25,25,0,1,0,41.38,26.14h207.8a25,25,0,1,0,41.92-25.57l101-174.88a25,25,0,0,0-2.21-49.8ZM432.53,492.18a25.37,25.37,0,0,0-2.9-.17,25,25,0,0,0-23.42,16.23H198.41A25,25,0,0,0,175,492q-1.05,0-2.07.09L69.75,313.42a25,25,0,0,0,.79-30.9L170.92,108.66a25,25,0,0,0,27.49-15.9H400A25,25,0,0,0,423.44,109a25.34,25.34,0,0,0,6.57-.87L530,281.37a25,25,0,0,0,1.45,39.39Z" transform="translate(-25.51 -58.99)"/></svg>
    </div>
  `,
  styleUrls: ['./landinglogosvg.component.scss'],
})
export class LandinglogosvgComponent implements OnInit, AfterViewInit {

  @ViewChild('landingLogoContainer') landingLogoContainer: ElementRef

  constructor() { }
  ngAfterViewInit(): void {
    const landingLogoContainer = this.landingLogoContainer.nativeElement;
    gsap.fromTo(landingLogoContainer, {opacity: 0}, {opacity: 1, duration: 1.5, ease: 'power1'});
  }

  ngOnInit(): void {
  }

}
