import { Component, OnInit } from '@angular/core';

import { PreviewService } from 'src/app/services/preview.service';

import { Preview } from 'src/app/projects';

@Component({
  selector: 'app-phonepreview',
  templateUrl: './phonepreview.component.html',
  styleUrls: ['./phonepreview.component.scss']
})
export class PhonepreviewComponent implements OnInit {

  projectPreview = {} as Preview;
  i: number;

  constructor(private previewService: PreviewService) { }

  ngOnInit(): void {
    this.i = 0;
    this.setContent();
    this.updateContent();
  }

  //This must be called everytime node changes but how
  private setContent(){
    if (this.i > 1){return}
    const projectPreview = this.previewService.projectPreview;
    this.projectPreview.projectID = projectPreview.projectID;
    this.projectPreview.projectImage = projectPreview.projectImage;
    this.projectPreview.projectInfoLink = projectPreview.projectInfoLink;
    this.projectPreview.projectLiveLink = projectPreview.projectLiveLink;
    this.projectPreview.projectVidLink = projectPreview.projectVidLink;
    this.i++;
  }

  private updateContent(){
    this.previewService.notifyObservable$.subscribe((previewData: Preview)=> {
      this.projectPreview.projectID = previewData.projectID;
      this.projectPreview.projectImage = previewData.projectImage;
      this.projectPreview.projectInfoLink = previewData.projectInfoLink;
      this.projectPreview.projectLiveLink = previewData.projectLiveLink;
      this.projectPreview.projectVidLink = previewData.projectVidLink;
    });

  }
}
